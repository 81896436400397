import { FC, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { topNavHeadingAtom } from '../../recoil/atoms/Workspace';
import TopNavPortal from '../../components/layout/top-menu/TopNavPortal';
import { TabStrip } from '../../components/shared/tab-strip/TabStrip';
import FormTemplates from './FormTemplates';
import ModuleTemplateList from './ModuleTemplateList';
import PicklistBuilder from './picklist-builder/PicklistBuilder';
import ResourceTemplates from './ResourceTemplates';
import { useTranslation } from 'react-i18next';
import { AdminLayout } from './Layout';
import { currentTenantIdAtom } from '../../recoil/atoms/Clients';

const AdminLanding: FC = () => {
  const setTopNavheading = useSetRecoilState(topNavHeadingAtom);
  const currentTenantId = useRecoilValue(currentTenantIdAtom);

  const { t } = useTranslation('admin');

  useEffect(() => {
    setTopNavheading(t('heading'));
    return () => {
      setTopNavheading('');
    };
  }, [setTopNavheading, t]);

  return (
    <AdminLayout>
      <div className="h-full">
        <TabStrip
          enableHash
          defaultTabId="form-builder"
          headerClassName="mt-2.5 -mb-2.5"
          contentClassName="px-6 bg-background-1"
          enableSticky
          wrapper={TopNavPortal}
          key={currentTenantId}
        >
          <TabStrip.TabHeader id="form-builder" text={t('tabs.form-builder')} value={null} />
          <TabStrip.TabHeader id="module-builder" text={t('tabs.module-builder')} value={null} />
          <TabStrip.TabHeader id="picklists" text={t('tabs.picklists')} value={null} />
          <TabStrip.TabHeader id="resource-builder" text={t('tabs.resource-builder')} value={null} />
          <TabStrip.TabContent forId="form-builder">
            <FormTemplates />
          </TabStrip.TabContent>
          <TabStrip.TabContent forId="module-builder">
            <ModuleTemplateList />
          </TabStrip.TabContent>
          <TabStrip.TabContent forId="picklists">
            <PicklistBuilder />
          </TabStrip.TabContent>
          <TabStrip.TabContent forId="resource-builder">
            <ResourceTemplates />
          </TabStrip.TabContent>
        </TabStrip>
      </div>
    </AdminLayout>
  );
};

export default AdminLanding;
